@import './variables';


.table {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: rgba($text-color, .8);
  background: #FFFFFF;
  border-radius: $border-radius;
  filter: drop-shadow(0px 16px 20px rgba(59, 69, 91, 0.04));

  th, td {
    padding: 16px 12px;
    border-bottom: 1px solid rgba(59, 69, 91, 0.08);

    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  > tbody tr:last-child > *,
  > tr:last-child > * {
    border-bottom: none;
  }
  th {
    font-weight: 600;
    line-height: 16px;
  }
  @media (min-width: $breakpoint-lg) {
    thead {
      td, th {
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
  }
}


@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    background: none;
    border-radius: 0;
    box-shadow: none;
    filter: none;

    thead {
      display: none;
    }
    tbody, tr, td, th {
      display: block;
      width: 100%;
    }

    tr {
      margin-bottom: 1rem;
      background: #FFFFFF;
      border-radius: $border-radius;
      filter: drop-shadow(0px 16px 20px rgba(59, 69, 91, 0.04));

      td:last-child {
        border-bottom: none;
      }
    }
    th, td {
      padding: 12px 20px;
    }
  }

  .td-responsive {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;

    &:before {
      content: attr(data-title);
      color: $text-muted;
      width: 100px;
      margin-right: 2em;
    }
    &-full {
      display: block;

      &:before {
        width: 100%;
        display: block;
        margin-right: 0;
        margin-bottom: 6px;
      }
    }
  }
}

.table-fixed {
  table-layout: fixed;
}

body .mat-sort-header {
  &-pointer-left,
  &-pointer-right,
  &-pointer-middle,
  &-stem,
  &-indicator {
    display: none;
  }

  .mat-sort-header-arrow {
    transform: none!important;
    height: 16px;
    width: 16px;
    min-width: 16px;
    &:after {
      content: "\e964";
      font-family: 'icomoon';
      color: #9BA4BA;
    }
  }
  &[aria-sort="ascending"] {
    .mat-sort-header-arrow:after {
      content: "\e969";
    }
  }
}
