@import './variables';

.card {
  display: block;
  position: relative;
  background: $card-color;
  border: none;
  box-shadow: 0 16px 20px rgba(59, 69, 91, 0.04);
  border-radius: 8px;
  transition: all 0.3s;

  &-header {
    background: none;
    padding: 24px;
    border-bottom: 1px solid rgba(59, 69, 91, 0.08);
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;

    &.border-none {
      border-bottom: none;
    }
  }
  &-content {
    padding: 24px;
    background: none;

    .table {
      filter: none;
      box-shadow: none;
      border-radius: 0 0 $border-radius $border-radius;
    }

    .card-content-data {
      p {
        img {
          width: 100%;
        }
      }
    }
    .investors {
      p {
        img {
          width: 25%;
        }
      }
      @media screen and (max-width: 500px){
        p {
          img {
            width: 50%;
          }
        }
      }
    }
  }
  &-footer {
    background: none;
    padding: 24px;
    border-top: 1px solid rgba(59, 69, 91, 0.08);
  }

  .tooltip-inside {
    width: 240px;
    max-width: 80%;
    top: 34%;
    text-align: left;
  }

  &a,
  a.card-inner {
    color: inherit;
    display: block;
  }

  &.--disabled {
    background: rgba($card-color, 0.4);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      .tooltip-inside {
        visibility: visible;
        opacity: 1;
        top: 30%;
      }
    }

    .card-footer,
    .card-header,
    .card-content {
      opacity: 0.4;

      a, button {
        pointer-events: none;
      }
    }
  }

  &.cursor-pointer:hover {
    background: #F5F6F8;
    box-shadow: 0 24px 32px rgba(59, 69, 91, 0.08);
  }
  &.not-found {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text-color-40;
    font-size: 16px;

    .icon {
      display: inline-block;
      margin-bottom: 12px;
      font-size: 46px;
      line-height: 1;
      color: $text-color-40;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 24px;

      .icon {
        font-size: 64px;
      }
    }
  }
}

.card-tooltip {
  display: inline-block;
  position: absolute;
  z-index: 10;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  padding: 8px 12px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1C253A;
  border-radius: $border-radius;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: $white;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
.show-card-tooltip:hover .card-tooltip {
  visibility: visible;
  opacity: 1;
  top: 50%;
}


.card-list {
  display: flex;
  flex-wrap: wrap;

  .card {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  @media (min-width: $breakpoint-lg) {
    &.per-row-2 {
      .card,
      .card:nth-child(odd) {
        margin-right: 20px;
        width: calc(50% - 10px);
        flex: 0 0 calc(50% - 10px);
      }
      .card:nth-child(even) {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px),
  (min-width: 1024px) and (max-width: 1365px) {
    &.per-row-3 {
      .card,
      .card:nth-child(odd) {
        margin-right: 20px;
        width: calc(50% - 10px);
        flex: 0 0 calc(50% - 10px);
      }

      .card:nth-child(even) {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 1366px) {
    &.per-row-3 {
      .card,
      .card:nth-child(odd) {
        margin-right: 20px;
        width: calc(33.33% - 14px);
        flex: 0 0 calc(33.33% - 14px);;
      }

      .card:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}



