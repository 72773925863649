@import './variables';

.deal-card {
  &__info {
    padding-top: 11px;
  }
  .deal-name {
    margin: 4px 0 8px;
  }
}
.token-name {
  display: flex;
  align-items: center;

  &__img {
    width: 40px;
    flex: 0 0 40px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  &__info {
    width: calc(100% - 40px);
    flex: 0 0 calc(100% - 40px);
    padding-left: 12px;
  }

  .token-title {
    letter-spacing: 0.15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .token-symbol {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $text-muted;
  }

  &-inline {
    .token-name__info {
      display: flex;
      align-items: center;
    }
    .token-title, .token-symbol {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin: 0;
    }
    .token-title {
      margin-right: 6px;
    }
  }

  &-sm {
    .token-name__img {
      width: 24px;
      flex: 0 0 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .token-name__info {
      width: calc(100% - 24px);
      flex: 0 0 calc(100% - 24px);
    }
  }
}

.network-name {
  display: flex;
  align-items: center;

  &__title {
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.15px;
  }
  &__img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 8px;
    vertical-align: initial;
  }
}


/* OLD */
.deal-progress-group {
  width: 100%;
  margin-top: 10px;

  @media (min-width: 992px) {
    width: 50%;
    margin-left: auto;
    margin-top: 0;
  }
}
.deal-network {
  display: flex;
  align-items: center;
}
.deal-network__name {
  color: rgba(5, 27, 82, .4);
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  margin-right: 8px;
}
.deal-network__img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}


.deal-title {
  font-size: 20px;
  line-height: 1.6;
}
.deal-title .currency {
  opacity: 0.4;
  margin: 0 0 5px;
  display: block;
}
.deal-title > a {
  color: inherit;
}
.deal-address {
  font-size: 14px;
  line-height: 1;
}
.deal-status {
  border-top: 1px solid rgba(59, 69, 91, 0.08);
  padding: 19px 20px;
  font-size: 14px;
}
.deal-status .badge-status {
  margin: 1rem 0;
}


.tab-section,
.deals-type-tab,
.deals-cat-tab {
  background: #ffffff;
  padding: 17px 20px 0;
}
.deals-cat-tab {
  padding-top: 15px;
}
.deals-cat-content {
  padding: 20px;
}

.deals-list {
  display: flex;
  flex-wrap: wrap;
}
.deals-list .deal-card {
  width: 100%;
  margin: 0 0 20px;
}
.deal-header {
  display: flex;
  align-items: center;
}

.deal-header .avatar {
  margin-right: 1rem;
}

.deal-total-convert,
.deal-total-raise {
  font-weight: 600;
}
.deal-total-raise {
  font-size: 24px;
  line-height: 1.33;
  margin: 2px 0 1px;
}

.deal-tier-table th {
  font-weight: 600;
}
.deal-tier-table th,
.deal-tier-table td {
  padding: 8px 24px;
}
@media (max-width: 767px) {
  .deal-info-table,
  .deal-info-table tr,
  .deal-info-table th,
  .deal-info-table td,
  .deal-tier-table,
  .deal-tier-table tr,
  .deal-tier-table th,
  .deal-tier-table td {
    display: block;
    width: 100%;
  }
  .deal-info-table th,
  .deal-tier-table th {
    padding-bottom: 0;
    padding-top: 6px;
    color: rgba(5, 27, 82, 0.48);
  }
  .deal-info-table th + td,
  .deal-tier-table th + td {
    padding-top: 0;
    padding-bottom: 6px;
  }
}
@media (min-width: 768px) {
  .deal-tier-table th {
    width: 160px;
  }
  .deal-title .currency {
    margin: 0 0 5px 0;
    display: inline-block;
  }

}
@media (min-width: 992px) {
  .deals-cat-content {
    padding: 40px;
  }
  .deal-status .badge-status {
    margin: 0 0 0 12px;
  }
  .deal-status .deal-date {
    margin-left: auto;
  }

  .tab-section,
  .deals-type-tab,
  .deals-cat-tab {
    padding: 17px 40px 0;
  }
}



/*----------------------------------------------------
  8. DEAL DETAIL
------------------------------------------------------*/
.deal-detail-header {
  padding-top: 40px;
}
.deal-detail-card .deal-header {
  padding: 20px 20px 0;
}
.deal-join {
  padding: 23px 20px 19px;
  display: flex;
  flex-wrap: wrap;
}
.deal-join > .btn {
  width: 100%;
  margin: 0 0 10px;
}
.deal-join > .btn:first-child {
  margin-left: 0;
}
.deal-join > .btn:last-child {
  margin-right: 0;
}
.deal-swap__amount {
  padding: 20px 20px 26px;
}
.deal-swap__progress {
  border-top: 1px solid rgba(59, 69, 91, 0.08);
  padding: 15px 20px 20px;
}
.deal-info {
  padding-top: 24px;
}
.deal-info-nav {
  padding: 14px 20px 0;
  border-bottom: 1px solid rgba(59, 69, 91, 0.08);
}
@media (min-width: 768px) {
  .deal-join > .btn {
    flex: 1 0 0;
    margin: 0 5px;
  }
}
@media (min-width: 992px) {
  .swap-progress {
    width: 64%;
    flex: 0 0 64%;
    padding-left: 2rem;
  }
  .deal-detail-header {
    padding-top: 40px;
  }
}
@media (min-width: 1280px) {
  .deal-detail-header__left,
  .deal-detail-header__right {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .deal-actions {
    position: absolute;
    right: 24px;
    top: 26px;
    padding: 0;
  }
  .create-deal-tab-content .tab-pane.active,
  .create-deal-form {
    min-height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
  }
  .create-deal-tab-content .tab-pane.active {
    min-height: calc(100vh - 144px);
  }
}
.deal-actions {
  display: inline-flex;
  align-items: center;
  padding: 24px 24px 0;
}
.deal-actions .btn-circle + .btn-circle {
  margin-left: 0.75em;
}
