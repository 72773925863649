@import "../styles/variables";

.cdk-overlay-container {
  z-index: 1059;
}

.cdk-overlay-backdrop {
  background: rgba(28, 37, 58, .8);
}
.dlg-light {
  .mat-dialog-container {
    padding: 0;
    border-radius: $border-radius;
    background: #FFFFFF;
    color: $text-color;
    filter: drop-shadow(0px 24px 32px rgba(59, 69, 91, 0.08));
    overflow: hidden;
    overflow-y: auto;

    @media (max-height: 550px) {
      height: auto;
    }
  }
}
.dlg-small {
  max-width: calc(100% - 40px)!important;
  width: 356px;

  @media (max-height: 550px) {
    max-height: 90%;
  }
}
.dlg-medium {
  max-width: calc(100% - 40px)!important;
  width: 612px;

  @media (max-height: 550px) {
    height: 80%;
  }
}

.dlg-large {
  max-width: calc(100% - 40px)!important;
  width: 700px;

  @media (max-height: 550px) {
    height: 80%;
  }
}


.btn-close {
  display: inline-block;
  position: absolute;
  right: 24px;
  top: 24px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  background: none;
  font-size: 24px;
  color: $text-muted;
  opacity: 1;
  z-index: 1;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:after {
    content: "\e963";
    font-family: "icomoon";
  }
  &:hover {
    color: $text-color;
  }
}

.modal-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 50px 0 24px;
  border-bottom: none;
  position: relative;

  .icon {
    margin-right: 12px;
    color: $text-muted;
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 12px;
  }
}

.modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 20px;

  .mat-icon {
    color: $primary-color;
    display: block;
    margin: 0 auto 24px;
  }

  &.alert-template {
    text-align: center;
  }
}

.wallet-choose img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}


/* Log modals */
.swal2-container {
  .swal2-content {
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: #051b52;
  }
  .swal2-styled.swal2-confirm {
    min-width: 100px;
    background-color: $green;
    padding: 0.75rem 1.5rem;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    border-radius: $border-radius;
  }
  .swal2-styled:focus {
    outline: none;
    box-shadow: none;
  }
}

body {
  .swal2-popup {
    max-width: calc(100% - 40px)!important;
    width: 356px;
  }
  .swal2-icon {
    &.swal2-info {
      border-color: $green;
      color: $green;
    }
    &.swal2-success {
      border-color: $green;
      color: $green;

      .swal2-success-ring {
        border: .25em solid rgba(51, 201, 192,.3);
      }
      [class^=swal2-success-line] {
        background-color: #33C9C0;
      }
    }
  }
}
