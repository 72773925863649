@import './variables';

input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

label {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
}
.req {
  color: $error;
}


body {
  .mat-form-field-subscript-wrapper {
    font-size: 12px;
    line-height: 18px;
    color: rgba($text-color, 0.48);
  }
  .mat-form-field {
    display: block;
    width: 100%;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  .mat-form-field-invalid {
    .mat-form-field-label {
      color: $error!important;
    }
    .mat-form-field-ripple,
    .mat-form-field-ripple.mat-accent {
      background: $error!important;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-underline {
    bottom: initial;

    &::before {
      background: #051B52!important;
    }
  }
  .mat-form-field-ripple {
    height: 1px!important;
  }
  .mat-hint {
    display: block;
    width: 100%;
    color: rgba($text-color, .48);

    &.text-right {
      order: 2;
    }

    + .mat-form-field-hint-spacer {
      display: none;
    }
  }
  .mat-form-field-label {
    letter-spacing: 0.5px;
    color: rgba($text-color, .4);
  }
  .mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: #F8FAFF;
      border-radius: $border-radius $border-radius 0 0;
      padding: 20px 16px 8px 16px;
    }
    &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-0.6em) scale(0.75);
    }
    .mat-form-field-infix {
      display: flex;
      border-top: none;
      padding: 4px 0 0;

      .mat-raised-button{
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    input.mat-input-element {
      margin-top: 0;
    }
    .mat-form-field-subscript-wrapper {
      position: static;
      margin: 0;
      padding: 4px 16px 0;
    }
    &.mat-form-field-disabled {
      opacity: 0.48;
      .mat-form-field-flex {
        background-color: #F8FAFF;
      }
    }
  }

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled,
  .mat-form-field-infix::after {
    color: $text-color;
  }

  // Input with image
  .icon-form-field {
    .mat-form-field-label-wrapper {
      left: 34px;
    }
    .mat-input-element,
    .mat-select-value-text {
      padding-left: 34px;
    }
    .mat-form-field-infix {
      img {
        position: absolute;
        left: -4px;
        top: -4px;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
  }

  // Toggle
  .mat-slide-toggle {
    &-bar {
      height: 20px;
      width: 34px;
      background: #D7DAE3;
      box-shadow: inset 0 1px 1px -0.5px rgba(74, 74, 104, 0.1);
      border-radius: 12px;
    }
    &-thumb {
      width: 16px;
      height: 16px;
      background: #FFFFFF;
      box-shadow: 0 2.75px 2.5px -1.5px rgba(14, 14, 44, 0.2), inset 0px -0.5px 0px rgba(14, 14, 44, 0.4);
      border-radius: 22px;

      &-container {
        width: 16px;
        height: 16px;
        top: 2px;
        left: 2px
      }
    }

    &.mat-checked {
      .mat-slide-toggle-thumb-container {
        transform: translate3d(13px, 0, 0);
      }
      .mat-slide-toggle-thumb {
        background-color: #FFFFFF;
      }
      .mat-slide-toggle-bar {
        background-color: $primary-color;
      }
      .mat-ripple-element {
        background-color: $primary-color;
      }
    }

  }
}

.form-select,
.form-control,
.form-floating > .form-control,
.form-floating > .form-select,
.form-floating > label {
  padding: 16px 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: normal;
}

.form-select,
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: $text-color;
  background-color: #F8FAFF;
  border: none;
  border-radius: $border-radius $border-radius 0 0;
  box-shadow: inset 0px -1px 0px #051B52;

  &:focus {
    background-color: #F8FAFF;
    outline: none;
    box-shadow: inset 0px -1px 0px $primary-color;
  }

  &:disabled,
  &:disabled,
  &[readonly] {
    opacity: 0.48;
    background: #F8FAFF;
    box-shadow: inset 0px -1px 0px rgba(5, 27, 82, 0.72);
    pointer-events: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-floating {
  position: relative;

  > .form-control,
  > .form-select {
    height: 56px;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px 16px;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    color: $text-muted;
    margin: 0;
    height: auto;
    transform: translateY(0);
    transition: all 0.2s;
  }
  > .form-control:focus,
  > .form-control:not(:placeholder-shown) {
    padding: 24px 16px 8px;
  }
  > .form-control:focus ~ label,
  > .form-control:not(:placeholder-shown) ~ label,
  > .form-select ~ label {
    font-size: 12px;
    line-height: 1;
    transform: translateY(-6px);
    opacity: 1;
  }
  > .form-control:focus ~ label {
    color: $primary-color;
  }
  > .form-control::-moz-placeholder {
    color: transparent;
  }
  > .form-control::placeholder {
    color: transparent;
  }

  .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }

  > .form-control:-webkit-autofill ~ label {
    font-size: 12px;
    line-height: 1;
    transform: translateY(-6px);
    opacity: 1;
  }
}

.form-select {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0078 6.46887L8.00087 10.4758L3.99394 6.46887L4.93675 5.52606L8.00087 8.59019L11.065 5.52606L12.0078 6.46887Z' fill='%239999A4'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: right 1rem center;
  padding-right: 2.5rem;
}
.form-select:focus {
  outline: none;
  box-shadow: inset 0px -1px 0px $primary-color;
}
input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: $text-color;
  opacity: 0.4;
}
input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: $text-color;
  opacity: 0.4;
}
input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: $text-color;
  opacity: 0.4;
}
input:-moz-placeholder,
.form-control:-moz-placeholder {
  color: $text-color;
  opacity: 0.4;
}

.form-check-input {
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  border: 2px solid $primary-color;
  width: 20px;
  height: 20px;
  margin-top: 1px;

  &[type="radio"] {
    border-radius: 100%;
  }
  &:focus {
    box-shadow: none;
  }
  &:checked {
    border-color: $primary-color;
    background-color: #ffffff;
  }
  &:checked[type="radio"] {
    background-image: radial-gradient(
        $primary-color 0%,
        $primary-color 40%,
        rgba(0, 0, 0, 0) 47%,
        rgba(0, 0, 0, 0) 100%
    );
  }
}

.form-check {
  margin-right: 26px;

  &.form-check-inline {
    display: inline-flex;
    align-items: center;
  }

  .form-check-label {
    font-size: 14px;
    min-width: 34px;
    margin: 0;
  }

  .form-check-input {
    margin: 0 16px 0 0;
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }
}




.form-switch {
  padding: 0;
  min-height: 14px;
  margin: 0;
}
.form-switch .form-check-input {
  border: none;
  height: 14px;
  margin: 0;
  background: rgba(5, 27, 82, 0.1);
}
.form-switch .form-check-input:focus {
  background: rgba(5, 27, 82, 0.1);
}
.form-switch .form-check-label {
  padding-left: 44px;
  display: block;
  position: relative;
  cursor: pointer;
  line-height: 1;
}
.form-switch .form-check-label:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #a1a5ae;
  position: absolute;
  left: 0;
  top: -3px;
  border-radius: 50%;
  transition: all 0.2s;
}
.form-switch .form-check-input:checked {
  background: rgba(51, 201, 192, 0.32);
}
.form-switch .form-check-input:checked ~ .form-check-label:before {
  background: $primary-color;
  left: 14px;
}

.form-group__label label,
.white-card__header label {
  margin-bottom: 0;
}



.input-group-icon {
  position: relative;
}
.input-group-icon .icon {
  display: inline-block;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  opacity: 0.48;
}
.input-group-icon .form-control {
  padding-left: 56px;
}
.valid-feedback,
.invalid-feedback {
  font-size: 12px;
  margin-left: 22px;
}
.time-input-group {
  display: flex;
  align-items: center;
}
.time-input-group .sep {
  padding: 0 8px;
}
.form-save {
  padding: 24px 20px;

  @media (min-width: $breakpoint-lg) {
    padding: 24px 40px;
  }
}

.input-group {
  border: 1px solid rgba(28, 37, 58, 0.2);
  border-radius: 2rem;

  &-text {
    background: none;
    border-radius: 0;
    border: none;
    border-right: 1px solid rgba(28, 37, 58, 0.2);
    font-size: 12px;
    line-height: 1;
    max-width: 100px;
    white-space: initial;
    color: $text-muted;
  }
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
    border: none;
  }
  > .form-control {
    border: none;
  }
  > .btn {
    border-radius: 2rem!important;
  }
}

.form-group-inline {
  display: flex;
  align-items: center;

  .btn,
  .mat-button-base {
    margin-left: 12px;
    flex: 0 0 auto;
  }

  @media (max-width: $breakpoint-md-max) {
    &.wrap-in-mob {
      flex-wrap: wrap;

      btn,
      .mat-button-base {
        margin-left: 0;
        margin-top: 12px;
        flex: 0 0 100%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .input-group-mob-full {
    display: block;
    border-radius: 1rem;

    .input-group-text {
      max-width: 100%;
      width: 100%;
      border-right: none;
      border-bottom: 1px solid rgba(28, 37, 58, 0.2);
      font-size: 14px;
      padding: 11px 24px;
    }
    .btn {
      width: 100%;
      border-radius: 1rem!important;
    }
    .form-control {
      width: 100%;
    }
  }
}


.form-control-date {
  padding-right: 50px;
  background-image: url('../assets/images/svg/calendar.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 24px) 50%;
}


.logo-upload {
  display: flex;
  align-items: center;

  &__input {
    width: calc(100% - 56px);
    flex: 0 0 calc(100% - 56px);
    padding-right: 10px;
  }

  &__preview {
    border: 1px solid #D7DAE3;
    border-radius: $border-radius;
    width: 56px;
    flex: 0 0 56px;
    height: 56px;
    padding: 7px;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      border-radius: 100%;
    }
  }
}

.disabled-value {
  padding: 8px 16px;
  background: #F8FAFF;
  opacity: 0.4;
  box-shadow: inset 0px -1px 0px #051B52;
  border-radius: $border-radius $border-radius 0 0;
  height: 56px;
  color: $text-color;

  &__label {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    opacity: 0.4;
  }
  &__value {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  &-img {
    position: relative;
    padding-left: 50px;

    img {
      position: absolute;
      left: 16px;
      top: 16px;
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
}


