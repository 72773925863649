@import './variables';

.tier-group {

  img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    object-fit: cover;
  }

  .tier-group-logo {
    margin-right: 8px;
  }
  .tier-group-chain {
    margin-right: 4px;
  }

  &__title {
    letter-spacing: 0.4px;
  }
  .progress-legend {
    margin-top: 4px;
  }
}

