// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@import "~@angular/material/theming";
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$bull-green: (
  50: #e0f2f1,
  100: #b2dfdb,
  200: #74DAD4,
  300: #74DAD4,
  400: #26a69a,
  500: #31C2B9,
  600: #289FA6,
  700: #289FA6,
  800: #289FA6,
  900: #289FA6,
  A100: #a7ffeb,
  A200: #64ffda,
  A400: #1de9b6,
  A700: #00bfa5,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$theme-color-primary: mat.define-palette($bull-green);
$theme-color-accent: mat.define-palette(mat.$orange-palette);
$theme-color-warn: mat.define-palette(mat.$red-palette);

$custom-theme: mat.define-light-theme((color: (primary: $theme-color-primary,
        accent: $theme-color-accent,
        warn: $theme-color-warn,
      ),
      typography: mat.define-typography-config($font-family: 'Mulish',
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.core-theme($custom-theme);
//@include angular-material-typography($custom-typography);

@import "./styles/variables";
@import "./styles/reboot";
@import "./styles/grid";
@import "./styles/typography";
@import './styles/input';
@import './styles/select';
@import './styles/button';
@import "./styles/alert";
@import './styles/table';
@import './styles/tabs';
@import "./styles/tooltip";
@import "./styles/dlg";
@import "./styles/card";
@import "./styles/progress";
@import "./styles/deals";
@import "./styles/tiers";
@import "./styles/ckeditor";

/*--- GENERAL ---*/
body,
html {
  min-height: 100%;
  height: 100%;
}

body {
  background-color: $bg-color;
}

.bg-white {
  background: $white;
}

.bg-dark {
  background: $dark !important;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 20px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
  color: $primary-color;
}

a:hover {
  color: #2fbbb7;
}

.label {
  font-weight: 600;
  font-size: 16px;
}

.small-label {
  font-size: 14px;
}

.value {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.24;
}

.value-sm {
  font-size: 18px;
}

.value-lg {
  font-size: 32px;
}

.convert-value {
  font-size: 16px;
  display: block;
}

.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.logo-24 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 100%;
}

.logo-20 {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 100%;
}

.logo-sm {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.logo-md {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.logo-sm img,
.logo-md img,
.contain-img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.p-40 {
  padding: 20px !important;
}

.pt-40 {
  padding-top: 20px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

.bg-dark {
  background: $dark  !important;
}

.border-top {
  border-top: 1px solid #f0f0f2 !important;
}

@media (max-width: 991px) {
  .border-md-bottom {
    border-bottom: 1px solid #f0f0f2;
  }

  .fs-md-18 {
    font-size: 18px;
  }

  .fs-md-14 {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .convert-value {
    display: inline-block;
  }

  .p-40 {
    padding: 40px !important;
  }

  .pt-40 {
    padding-top: 40px !important;
  }

  .px-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}

@media (min-width: 992px) {
  .border-lg-end {
    border-right: 1px solid #f0f0f2;
  }

  .pb-lg-32 {
    padding-bottom: 32px !important;
  }

  .h-lg-100 {
    min-height: 100%;
  }
}

@media (min-width: 1366px) {
  .col-laptop-6 {
    width: 50%;
  }
}

.form-control[readonly] {
  opacity: 1;
}

@media (min-width: 992px) {
  .form-group-inline .white-card__header {
    margin-right: auto;
    border-bottom: 0;
  }

  .form-group-inline .white-card__content {
    padding: 12px 24px 12px 0;
  }

  .form-group-inline label {
    margin-bottom: 0;
  }
}

/*----------------------------------------------------
  6. MEDIA UPLOAD
------------------------------------------------------*/
.upload-control__dd {
  background: rgba(28, 37, 58, 0.02);
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' rx='8' ry='8' style='fill: none; stroke: rgba(28, 37, 58, 0.32); stroke-width: 1; stroke-dasharray: 8 8'/></svg>");
  border-radius: 8px;
  height: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.upload-image__title,
.upload-placeholder {
  opacity: 0.4;
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
}

.upload-image img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: contain;
}

.upload-placeholder .icon {
  font-size: 40px;
}

.upload-control__btn {
  margin-top: 1em;
}


/*----------------------------------------------------
  7. DROPDOWN
------------------------------------------------------*/
.btn.dropdown-toggle {
  text-align: left;
  padding-right: 50px;
  padding-left: 24px;
  z-index: 1001;
  position: relative;
}

.btn-icon.dropdown-toggle {
  justify-content: flex-start;
}

.dropdown-toggle::after {
  content: "\e910";
  font-family: "icomoon";
  border: none;
  vertical-align: middle;
  font-size: 12px;
  position: absolute;
  right: 27px;
  top: 50%;
  margin-top: -8px;
}

.dropdown-menu {
  min-width: 100%;
  border: none;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(59, 69, 91, 0.12);
  border-radius: 0 0 1.5rem 1.5rem;
  padding: 0;
  overflow: hidden;
  z-index: 250;
}

.btn.dropdown-toggle+.dropdown-menu {
  padding: 3rem 0 0;
  border-radius: 1.5rem;
  transform: translateY(0) !important;
}

.dropdown-item {
  font-size: 14px;
  line-height: 1.44;
  font-weight: 600;
  color: $text-color;
  padding: 14px 24px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: $text-color;
  background: rgba(28, 37, 58, 0.08);
}

@media (max-width: 991px) {
  .update-val+input {
    width: 100%;
    margin-top: 16px;
  }
}

@media (min-width: 992px) {
  .update-val {
    margin: 0 0 8px;
  }
}

/*----------------------------------------------------
  7. ASIDE
------------------------------------------------------*/
.nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dashboard-aside {
  top: 72px;
  color: #ffffff;
  border-right: none;
  z-index: 5000;
}

.dashboard-aside.active {
  transform: translateX(0);
}

.dashboard-logo {
  padding: 1.5rem;
}

.dashboard-nav .nav-item {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 0 100%;
  padding: 1rem 1.5rem;
  transition: all 0.2s;
}

.dashboard-nav .nav-link {
  color: #ffffff;
  padding: 0;
  flex: 1 0 0%;
}

.dashboard-nav .nav-item:hover,
.dashboard-nav .nav-item.active {
  background: rgba(78, 255, 212, 0.08);
}

.nav-link__icon {
  margin-right: 0.7rem;
}

.nav-link__action {
  opacity: 0;
  visibility: hidden;
  margin-left: auto;
  color: #4effd4;
  font-size: 24px;
  transition: all 0.3s;
}

.nav-item:hover .nav-link__action {
  opacity: 1;
  visibility: visible;
}

.nav-link__action:hover {
  color: $primary-color;
}

.desktop-logo {
  display: none;
}

.mob-menu-toggle {
  display: inline-block;
  padding: 0;
  border: none;
  position: relative;
  width: 28px;
  height: 18px;
  background: none;
  opacity: 0.4;
  transition: opacity 0.3s;
}

.mob-menu-toggle:hover {
  opacity: 0.8;
}

.mob-menu-toggle b {
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  left: 0;
  top: 50%;
  background: #ffffff;
  margin-top: -1px;
  transform: rotate(0);
  transition: all 0.3s;
}

.mob-menu-toggle b:before,
.mob-menu-toggle b:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  right: 0;
  background: #ffffff;
}

.mob-menu-toggle b:before {
  top: -7px;
  transform: rotate(0);
  transition: all 0.3s;
}

.mob-menu-toggle b:after {
  top: 9px;
  width: 80%;
}

.mob-menu-header {
  height: 72px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 22px 20px;
  color: #ffffff;
  z-index: 1060;

  &__title {
    margin: 0 0 0 1.5rem;
    line-height: 1.4;
  }
}

.mobile-logo {
  margin-left: auto;
}

.dashboard-role {}

.sidenav {
  transform: translateX(-100%);
  visibility: hidden;
  transition: transform 0.3s;
}

@media (max-width: 1199px) {
  .mob-menu-active {
    overflow: hidden;

    .sidenav {
      transform: translateX(0);
      visibility: visible;
    }

    .mob-menu-toggle b {
      transform: rotate(-45deg);

      &:before {
        transform: rotate(90deg);
        top: 1px;
      }

      &:after {
        opacity: 0;
      }
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .sidenav {
    transform: translateX(0);
    visibility: visible;
  }

  .mob-menu-header {
    display: none;
  }

  .mob-menu-toggle,
  .mobile-logo {
    display: none;
  }

  .desktop-logo {
    display: block;
  }
}

/*----------------------------------------------------
  3. CONTENT
------------------------------------------------------*/
.dashboard-content {
  padding: 72px 0 0;
}

.dashboard-section {
  padding-right: 20px;
  padding-left: 20px;
}


.white-card {
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(59, 69, 91, 0.04);
  border-radius: 16px;
}

.white-card__header {
  border-bottom: 1px solid rgba(59, 69, 91, 0.08);
}

.form-group-inline .white-card__header {
  border-bottom: 0;
}

.white-card__header,
.white-card__content,
.white-card__footer {
  padding: 1rem;
}

.white-card__alerts .alert {
  border-radius: 0;
}

.white-card__footer {
  border-top: 1px solid rgba(59, 69, 91, 0.08);
}

.coin-title {
  display: flex;
  align-items: center;
}

.coin-title__logo {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(98, 126, 234, 0.16);
  border-radius: 100%;
}

.coin-title__logo.chain-56 {
  background: #FFF2CA
}

.coin-title__logo img {
  object-fit: contain;
  max-width: 19px;
}

.coin-title__title {
  padding-left: 12px;
}

@media (max-width: 767px) {
  .white-card__content.pb-32 {
    padding-bottom: 1rem !important;
  }
}

@media (min-width: 992px) {

  .white-card__header,
  .white-card__content,
  .white-card__footer {
    padding: 1.5rem;
  }
}

@media (min-width: $breakpoint-xl) {
  .dashboard-content {
    padding: 0 0 0 252px;
  }

  .dashboard-section {
    padding-right: 40px;
    padding-left: 40px;
  }
}

/*----------------------------------------------------
  7. MODAL
------------------------------------------------------*/


/*----------------------------------------------------
  7. CONTENT HEADER
------------------------------------------------------*/
.dashboard-header {
  padding: 20px;
  border-bottom: 1px solid rgba(59, 69, 91, 0.08);
  background: #ffffff;
}

.dashboard-header__buttons {
  width: 100%;
}

.dashboard-header__buttons .btn {
  margin: 0 0 10px;
  width: 100%;
}

.dashboard-header__buttons .btn:last-child {
  margin-bottom: 0;
}

.wallet {
  display: flex;
  align-items: center;
  border: 1px solid #dfe0eb;
  border-radius: 2rem;
  padding: 7px;
  font-size: 14px;
}

.wallet-icon {
  width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
}

.wallet-icon img {
  height: 26px;
}

.wallet-value {
  font-weight: 600;
  margin-left: 3px;
  margin-right: 1rem;
}

.wallet-address {
  background: rgba(5, 27, 82, 0.04);
  color: $text-color;
  border-radius: 24px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
}

.token-address {
  display: inline-block;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  padding: 8px 10px 8px 8px;
}

.wallet-address-sm {
  padding: 0 0.75rem;
  border-radius: 16px;
}

.wallet-address-sm .token-address {
  padding: 0 10px 0 0;
}

.copy-icon {
  font-size: 24px;
  padding: 0;
  border: none;
  background: no-repeat;
  transition: color 0.2s ease-out;
}

.copy-icon:hover {
  color: $primary-color;
}

/*----------------------------------------------------
  8. PENALTIES
------------------------------------------------------*/
.penalties-table {
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  box-shadow: none;
  filter: none;

  td,
  th {
    padding: 16px 24px;
  }

  th {
    font-weight: normal;
    letter-spacing: 0.25px;
  }

  td {
    letter-spacing: 0.1px;
    font-weight: 500;
    text-align: right;
  }
}



.avatar img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  object-fit: cover;
}

.badge-status {
  position: relative;
  border-radius: 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 16px 8px 24px;
  background: rgba(5, 27, 82, 0.08);
}

.badge-status:before {
  content: "";
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  left: 8px;
  top: 12px;
  border-radius: 100%;
  background: #051b52;
}

.badge-status.status-filled {
  background: rgba(51, 201, 192, 0.08);
  color: #33c9c0;
}

.badge-status.status-filled:before {
  background: #33c9c0;
}

.progress-legend-bull {
  margin-top: -52px;
}

.progress-legend__bull {
  flex: 1 0 0%;
  text-align: center;
  position: relative;
}

.progress-legend__bull>* {
  text-align: center;
  display: block;
  margin: 0 auto;
  max-width: 50px;
}

.legend-bull-title:before {
  content: "";
  height: 34px;
  width: 2px;
  background: #ffffff;
  display: block;
  margin: 0 auto;
}

.legend-bull-title {
  font-weight: 600;
  line-height: 1;
}

.legend-bull-value {
  line-height: 1;
  margin-top: 4px;
}

.progress-bull-total {
  padding-top: 45px;
  position: relative;
  overflow: hidden;
}

.progress-bull-total .progress {
  height: 8px;
}

.status-upcoming .badge-status:before,
.status-progress .badge-status:before {
  background: $orange;
}

.status-filled .badge-status:before {
  background: $primary-color;
}

@media (min-width: 768px) {
  .progress-legend__bull>* {
    max-width: initial;
  }
}




/*----------------------------------------------------
  8. ADMIN
------------------------------------------------------*/

.kys-status {
  display: inline-block;
  padding: 9px 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  border-radius: 16px;
}

.kys-status.status-success {
  color: #27ae60;
  background: #eef9f2;
}

.kys-status.status-pending {
  color: #f2994a;
  background: #fef7f1;
}

.users-list {
  table-layout: fixed;
}

.users-list td,
.users-list th {
  padding: 12px;
  vertical-align: middle;
}

.users-list th {
  line-height: 32px;
}

.users-list td:first-child,
.users-list th:first-child {
  padding-left: 24px;
}

.users-list td:last-child,
.users-list th:last-child {
  padding-right: 24px;
}

.users-list a {
  color: inherit;
}

.users-list th:first-child {
  width: 70px;
}



/*----------------------------------------------------
  8. POOLS
------------------------------------------------------*/
.pool-list {
  display: flex;
  flex-wrap: wrap;
}

.pool-card {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(59, 69, 91, 0.08);
  border-radius: 16px;
  margin-bottom: 10px;
}

.pool-title {
  font-size: 20px;
  line-height: 32px;
}

.pool-info__row {
  padding: 4px 0;
}

.pool-info a {
  color: $text-color;
  text-decoration: underline;
}

.pool-progress .progress-legend {
  margin-top: 6px;
  font-size: 14px;
}

.pool-card__header,
.pool-card__info,
.pool-card__footer {
  padding: 24px;
}

.pool-card__header {
  padding-bottom: 0;
}

.pool-card__info {
  padding-top: 12px;
  padding-bottom: 20px;
}

.pool-card__footer {
  border-top: 1px solid rgba(59, 69, 91, 0.08);
}

@media (min-width: 768px) {
  .pool-list .pool-card {
    width: calc(50% - 5px);
  }

  .pool-list .pool-card:nth-child(odd) {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .pool-list .pool-card {
    width: calc(33.33% - 7px);
    margin-right: 10px;
  }

  .pool-list .pool-card:nth-child(3n) {
    margin-right: 0;
  }
}


.staking-modal-content__text {
  margin: 30px 0;
}

.staking-modal-content__text p {
  opacity: 0.5;
}

.staking-modal-content h4 {
  font-size: 18px;
  font-weight: bold;
}


.social-list img {
  opacity: 0.4;
  transition: opacity 0.2s;
}

.social-list a:hover img {
  opacity: 0.8;
}



.address-btn {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 12px 10px 49px;
  background: #F8FAFF;
  border: none;
  text-align: left;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  letter-spacing: 0.25px;
  color: $text-color;

  span {
    display: block;
    max-width: 100%;
    overflow-wrap: anywhere;
    white-space: normal;
  }

  &:before {
    font-family: 'icomoon';
    position: absolute;
    left: 12px;
    opacity: 0.4;
    font-size: 24px;
  }

  &:hover {
    color: $text-color;
    background: #EEF1F8;
  }

  &.--link:before {
    content: "\e972";
  }

  &.--copy {
    &:before {
      content: "\e965";
    }

    &:after {
      content: "✔ Copied";
      display: block;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 12px;
      color: #FFFFFF;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1C253A;
      border-radius: 8px;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      top: -5px;
      left: 50%;
      transform: translate(-50%, -100%);
    }

    &:focus:after {
      animation: copied 2s;
    }
  }
}

@keyframes copied {
  0% {
    opacity: 1;
    visibility: visible;
  }

  90% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: visible;
  }
}

.loading-page {
  position: relative;

  &:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(28, 37, 58, .3);
    z-index: 1020;
  }

  &:after {
    content: '';
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 6px;
    text-indent: -9999em;
    border-top: 1.1em solid #FFFFFF;
    border-right: 1.1em solid #FFFFFF;
    border-bottom: 1.1em solid #FFFFFF;
    border-left: 1.1em solid $primary-color;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loading-spinner 1.1s infinite linear;
    animation: loading-spinner 1.1s infinite linear;
    position: fixed;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    z-index: 1021;

    @media (min-width: $breakpoint-xl) {
      left: calc(50% + 96px);
    }
  }
}

@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

re-captcha {
  display: block;
}


.sign-up-form {
  max-width: 544px;
  margin: 0 auto;

  img {
    margin-bottom: 1.5em;
    max-width: 72px;
  }

  .__icon {
    display: inline-block;
    font-size: 49px;
    line-height: 1;
    margin: 0 auto 16px;
    color: $primary-color;
  }
}


.dm-donut {
  display: inline-block;
  border-radius: 100%;
  height: 160px;
  width: 160px;
  margin: 0;
  position: relative;
  background: #FFFFFF;

  &.--placeholder {
    border: 6px solid #D7DAE3;
  }
}

.dm-donut__slice {
  height: 100%;
  position: absolute;
  width: 100%;
}

.dm-donut__slice_before,
.dm-donut__slice_after {
  border: 6px solid rgba(49, 194, 185, 0.24);
  border-radius: 50%;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: rotate(45deg) translateZ(0);
  transform: rotate(45deg) translateZ(0);
  width: 100%;
  transition: all 0.2s;
}

.dm-donut__slice_before {
  border-width: 6px;
  border-top-color: #FFFFFF;
}

.dm-donut:after {
  content: '';
  position: absolute;
  display: block;
  left: 50%;
  top: 22px;
  margin-left: -1px;
  background: #FFFFFF;
  width: 1px;
  height: 35px;
  z-index: 2;
}

.legend-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0;
  box-shadow: inset 0px -1px 0px rgba(59, 69, 91, 0.08);
  font-size: 14px;
  line-height: 16px;

  &__point {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 10px;
  }

  &__blp {
    margin-left: auto;
  }

  a {
    color: inherit;

    &:hover {
      color: $primary-color;
    }
  }
}




@media only screen and (min-width: 576px) {
  .dm-donut-legend-item__val {
    padding: 13px;
    font-size: 24px;
  }
}


.checked-list {

  &ul,
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 0 28px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "\e961";
      font-family: 'icomoon';
      position: absolute;
      left: 0;
      top: 0;
      color: $primary-color;
      font-size: 20px;
    }
  }
}

body .mat-menu-panel {
  min-height: 56px;
  filter: drop-shadow(0px 24px 32px rgba(59, 69, 91, 0.08));
  box-shadow: 0 16px 20px rgba(59, 69, 91, 0.04);
  border-radius: 0 0 $border-radius $border-radius;

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  .mat-menu-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    height: auto;
    padding: 16px;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: 10px;
    }

    .icon {
      margin-right: 10px;
    }
  }

  &.select-network-menu {
    min-width: calc(100vw - 40px);

    @media (min-width: $breakpoint-sm) {
      min-width: 200px;
    }
  }

  &.more-menu {
    min-width: 200px;
    border-radius: $border-radius !important;
    margin-right: 40px;
    margin-top: -40px;
  }
}

.ql-video {
  width: 100%;
  height: calc((var(--element-width) - 24px) / 1.777);
  max-width: 100%;
}

.faq-video {
  .ql-video {
    width: 100%;
    height: calc((var(--faq-width) - 80px) / 1.777);
    max-width: 100%;
  }
}

.user-poll-description {
  img {
    width: 100%;
  }
}

body .mat-form-field-label-wrapper {
  overflow: visible !important;
}

.mat-form-field-label {
  top: -8px !important;
}
